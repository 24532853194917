// App.js
import React from 'react';
import './fonts.css';
import { AboutUs, Chef, CookrPlus, FAQ, Footer, Gallery, Header, Intro, Laurels, Plans, SpecialMenu } from './container';
import { Navbar } from './components';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18';
import './App.css';

const App = () => (
  <I18nextProvider i18n={i18n}>
    <div>
      <Navbar />
      <Header />
      <AboutUs />
      <SpecialMenu />
      <Plans/>
      <CookrPlus/>
      <FAQ/>
      <Footer />
    </div>
  </I18nextProvider>
);

export default App;
