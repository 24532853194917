import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <div className="app__footer-content">
      <div className="app__footer-left">
        <img src={images.gericht} alt="footer_logo" className="footer-logo" />
        <p>© 2024 Cookr | All Rights Reserved.</p>
      </div>
      <div className="app__footer-right">
        <div className="footer-links">
          <a href="#partner">Become a Partner</a>
          <a href="#plans">Monthly Plans</a>
          <a href="#cookr-plus">Cookr Plus</a>
          <a href="#faqs">FAQs</a>
        </div>
        <div className="footer-icons">
          <img src={images.instagram} alt="Instagram" />
          <img src={images.linkedin} alt="LinkedIn" />
          <img src={images.tiktok} alt="TikTok" />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
