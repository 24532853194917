// Header.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { images, headervideo } from '../../constants';
import './Header.css';
import i18n from '../../i18';

const Header = () => {
  const { t } = useTranslation();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [playVideo, setPlayVideo] = React.useState(false);
  const vidRef = React.useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted:', { username, password, rememberMe });
    setUsername('');
    setPassword('');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <nav className="app_navbar">
        <div className="app_navbar-left">
          <img src={images.gericht} alt="nav_logo" className="app_navbar-logo" />
          <div className="app_navbar-download">
            <span className="new-label">New</span>
            <span className="download-text">{t('Download cookr App')} <span className="arrow-right">→</span></span>
          </div>
        </div>
        <div className="app_navbar-links">
          <a href="#" className="app_navbar-link">{t('Become a Partner')}</a>
          <a href="#" className="app_navbar-link">{t('Monthly Plans')}</a>
          <a href="#" className="app_navbar-link">{t('Cookr Plus')}</a>
          <a href="#" className="app_navbar-link">{t('FAQs')}</a>
          <div className="app_navbar-link language-switcher">
            <img src={images.language} alt="language_icon" className="app_navbar-icon" />
            <select onChange={(e) => changeLanguage(e.target.value)} className="language-select">
              <option value="en">English</option>
              <option value="de">German</option>
              <option value="ar">Arabic</option>
            </select>
          </div> 
          <a href="https://instagram.com/cookr.at" className="app_navbar-link">
            <img src={images.instagram} alt="instagram_logo" className="app_navbar-icon" />
          </a>
          <a href="https://tiktok.com/@cookr.at" className="app_navbar-link">
            <img src={images.tiktok} alt="tiktok_logo" className="app_navbar-icon" />
          </a>
        </div>
      </nav>
      <div className="app__header app__wrapper section__padding" id="home">
        <div></div>
        <div className="app__wrapper_info">
          <form className="login-form" onSubmit={handleSubmit}>
            <h2>{t('Expand with 0% Commission')}</h2>
            <p className="sub-heading">{t('Boost your customer base without any fees. Control prices and enhance service on our commission-free platform.')}</p>
            <button type="submit" className="custom-button">{t('Join as a Restaurant')}</button>
          </form>
        </div>
        <div className="app__wrapper_img">
          <video src={headervideo} type="video/mp4" loop controls={false} muted className="header-video" />
        </div>
      </div>
    </div>
  );
};

export default Header;


