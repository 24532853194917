import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants'; // Adjust the import based on your project structure
import './FAQ.css'; // Import the CSS file for styling

const FAQ = () => {
  const { t } = useTranslation();
  
  const faqs = [
    {
      question: t('faqs.question1'),
      answer: t('faqs.answer1')
    },
    {
      question: t('faqs.question2'),
      answer: t('faqs.answer2')
    },
    {
      question: t('faqs.question3'),
      answer: t('faqs.answer3')
    },
    {
      question: t('faqs.question4'),
      answer: t('faqs.answer4')
    },
    {
      question: t('faqs.question5'),
      answer: t('faqs.answer5')
    },
    {
      question: t('faqs.question6'),
      answer: t('faqs.answer6')
    }
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1>{t('faqs.title')}</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleExpand(index)}>
            <img src={images.expand1} alt="Expand Icon" className="expand-icon" />
            {faq.question}
          </div>
          {expandedIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
