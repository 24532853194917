import bg from '../assets/bg.png';
import google from '../assets/google.png';
import apple from '../assets/apple.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import state1 from '../assets/state1.png';
import state2 from '../assets/state2.png';
import state3 from '../assets/state3.png';
import state4 from '../assets/state4.png';
import Burger from '../assets/Burger.png';
import Logo1 from '../assets/1 (2).png';
import Logo2 from '../assets/2.png';
import Logo3 from '../assets/3.png';
import Logo4 from '../assets/4.png';
import Logo5 from '../assets/5.png';
import Logo6 from '../assets/6.png';
import Logo7 from '../assets/7.png';
import Logo8 from '../assets/8.png';
import Logo9 from '../assets/9.png';
import Logo10 from '../assets/10.png';
import Logo11 from '../assets/11.png';
import Logo12 from '../assets/12.png';
import Logo13 from '../assets/13.png';
import Logo14 from '../assets/14.png';
import Logo15 from '../assets/15.png';
import Logo16 from '../assets/16.png';
import Logo17 from '../assets/17.png';
import Logo18 from '../assets/18.png';
import Logo19 from '../assets/19.png';
import Logo20 from '../assets/20.png';
import instagram from '../assets/instagram.png';
import tiktok from '../assets/tiktok.png';
import linkedin from "../assets/linkedin.png"
import language from '../assets/language.png';
import orderFood from "../assets/Order food.png";
import expand1 from "../assets/icon-expand1.png";
import expand2 from "../assets/icon-expand2.png";
import cookr1 from "../assets/Cookr App.png";
import cookr2 from "../assets/Cookr App 2.png";
import shop from "../assets/shop.png"


export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  state1,
  state2,
  state3,
  state4,
  Burger,
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  Logo13,
  Logo14,
  Logo15,
  Logo16,
  Logo17,
  Logo18,
  Logo19,
  Logo20,
  google,
  apple,
  instagram,
  tiktok,
  language,
  orderFood,
  expand1,
  expand2,
  cookr1,
  cookr2,
  shop,
  linkedin,

};
