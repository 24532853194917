import React from 'react';
import { images } from './constants';
import './ComingSoon.css'; // Import the CSS file for styling

const ComingSoon = () => {
  return (
    <div className="ComingSoon-container">
      
      <div className="image-stack">
  <div className="cookr-images"> 
    <img src={images.cookr1} alt="Cookr Image 1" className="image1" />
    <img src={images.cookr2} alt="Cookr Image 2" className="image2" />
  </div>
</div>

    </div>
  );
}

export default ComingSoon;
