import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants';
import './Plans.css'; // Import the CSS file for styling

const Plans = () => {
  const { t } = useTranslation();

  return (
    <div className="plans-container">
      <h1>{t('plans.title')}</h1>
      <p className="plans-description">
        {t('plans.description')}
      </p>
      <button className="plans-button">{t('plans.button_text')}</button>
      <div className="image-stack">
        <div className="cookr-images">
          <img src={images.cookr1} alt="Cookr Image 1" className="image1" />
          <img src={images.cookr2} alt="Cookr Image 2" className="image2" />
        </div>
        <div className='shop-section'>
          <img src={images.shop} alt="shop-image" className='shop-image'/>
          <img src={images.Burger} alt="Burger Image" className="burger-image" />
        </div>
      </div>
    </div>
  );
}

export default Plans;
