import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import images from '../../constants/images';
import './Navbar.css';
import i18n from '../../i18';

const Navbar = () => {
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <p>{t('navbar.feeling_hungry')}</p>
      </div>
      
      <div className="app__navbar-login">
        <img src={images.google} alt="google__logo" className="app__navbar-google-logo"/>
        <div />
        <img src={images.apple} alt="apple__logo" className="app__navbar-apple-logo"/>
      </div>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>{t('navbar.become_partner')}</a></li>
              <li><a href="#awards" onClick={() => setToggleMenu(false)}>{t('navbar.monthly_plans')}</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>{t('navbar.cookr_plans')}</a></li>
              <li><a href="#awards" onClick={() => setToggleMenu(false)}>{t('navbar.faqs')}</a></li>
            </ul>
            <ul className="app__navbar-smallscreen_links_">
              <li>
                <div className="app_navbar-link language-switcher">
                  <img src={images.language} alt="language_icon" className="app_navbar-icon" />
                  <select onChange={(e) => changeLanguage(e.target.value)} className="language-select">
                    <option value="en">English</option>
                    <option value="de">German</option>
                    <option value="ar">Arabic</option>
                  </select>
                </div> 
              </li>
              <li>
                <a href="https://instagram.com/cookr.at" className="">
                <img src={images.instagram} alt="instagram_logo" className="app_navbar-icon" />
                </a>
              </li>
              <li>
                <a href="https://tiktok.com/@cookr.at" className="">
                 <img src={images.tiktok} alt="tiktok_logo" className="app_navbar-icon" />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>  
    </nav>
  );
};

export default Navbar;
