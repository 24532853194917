import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants';
import './CookrPlus.css'; // Import the CSS file for styling

const CookrPlus = () => {
  const { t } = useTranslation();

  return (
    <div className="cookrplus-container">
      <h1>{t('cookrplus.title')}</h1>
      <p className="plans-description">
        {t('cookrplus.description')}
      </p>
      <button className="plans-button">{t('cookrplus.button_text')}</button>
    </div>
  );
}

export default CookrPlus;
