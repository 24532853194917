import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
      <div className="app__aboutus-overlay flex__center">
        <div className="aboutus-content">
          <h1 className="aboutus-title">
            <span>{t('aboutus.title')}</span><br />
          </h1>

          <div className="image-row">
            <div className="image-caption">
              <div className='image-shape'>
                <img src={images.state1} alt="State 1" className="image" />
              </div>
              <p>{t('aboutus.step1')}</p>
              <h6 className='h6'>{t('aboutus.step1_desc')}</h6>
            </div>
            <div className="image-caption">
              <div className='image-shape'>
                <img src={images.state2} alt="State 2" className="image" />
              </div>
              <p>{t('aboutus.step2')}</p>
              <h6 className='h6'>{t('aboutus.step2_desc')}</h6>
            </div>
            <div className="image-caption">
              <div className='image-shape'>
                <img src={images.state3} alt="State 3" className="image" />
              </div>
              <p>{t('aboutus.step3')}</p>
              <h6 className='h6'>{t('aboutus.step3_desc')}</h6>
            </div>
            <div className="image-caption">
              <div className='image-shape'>
                <img src={images.state4} alt="State 4" className="image" />
              </div>
              <p>{t('aboutus.step4')}</p>
              <h6 className='h6'>{t('aboutus.step4_desc')}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
