import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants';
import './SpecialMenu.css';
import axios from "axios";

const SpecialMenu = () => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    country: '',
    city: '',
    businessType: '',
    storeName: '',
    ownerName: '',
    phoneNumber: '',
    email: '',
    referralCode: ''
  });

  const countryCityMap = {
    austria: [
      'Vienna', 'Graz', 'Linz', 'Salzburg', 'Innsbruck', 'Klagenfurt', 'Villach',
      'Wels', 'Sankt Pölten', 'Dornbirn', 'Wiener Neustadt', 'Steyr', 'Feldkirch',
      'Bregenz', 'Leonding', 'Klosterneuburg', 'Baden bei Wien', 'Wolfsberg', 'Leoben',
      'Krems an der Donau', 'Traun', 'Amstetten', 'Lustenau', 'Kapfenberg', 'Mödling',
      'Hallein', 'Kufstein', 'Traiskirchen', 'Schwechat', 'Braunau am Inn'
    ],
    germany: [
      'Berlin', 'Munich', 'Hamburg', 'Frankfurt', 'Cologne', 'Stuttgart', 'Düsseldorf',
      'Dortmund', 'Essen', 'Leipzig', 'Bremen', 'Dresden', 'Hanover', 'Nuremberg',
      'Duisburg', 'Bochum', 'Wuppertal', 'Bielefeld', 'Bonn', 'Münster', 'Karlsruhe',
      'Mannheim', 'Augsburg', 'Wiesbaden', 'Mönchengladbach', 'Gelsenkirchen',
      'Braunschweig', 'Aachen', 'Kiel', 'Chemnitz', 'Halle (Saale)', 'Magdeburg',
      'Freiburg im Breisgau', 'Krefeld', 'Lübeck', 'Oberhausen', 'Erfurt', 'Mainz',
      'Rostock', 'Kassel', 'Hagen', 'Saarbrücken', 'Hamm', 'Mülheim an der Ruhr',
      'Potsdam', 'Ludwigshafen', 'Oldenburg', 'Leverkusen', 'Osnabrück', 'Solingen',
      'Heidelberg', 'Herne', 'Neuss', 'Darmstadt', 'Paderborn', 'Regensburg',
      'Ingolstadt', 'Würzburg', 'Wolfsburg', 'Fürth', 'Offenbach am Main', 'Ulm',
      'Heilbronn', 'Pforzheim', 'Göttingen', 'Bottrop', 'Trier', 'Recklinghausen',
      'Reutlingen', 'Bremerhaven', 'Koblenz', 'Bergisch Gladbach', 'Jena', 'Remscheid',
      'Erlangen', 'Moers', 'Siegen', 'Hildesheim', 'Salzgitter'
    ],
    emirates: [
      'Abu Dhabi', 'Dubai', 'Sharjah', 'Al Ain', 'Ajman', 'Ras Al Khaimah',
      'Fujairah', 'Umm Al Quwain', 'Khor Fakkan', 'Dibba Al-Fujairah', 'Kalba',
      'Jebel Ali', 'Dibba Al-Hisn', 'Ruwais', 'Liwa Oasis', 'Madinat Zayed'
    ],
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setCities(countryCityMap[country] || []);
    setFormData(prevState => ({
      ...prevState,
      country: country
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Send the form data to the API
    try {
      const response = await axios.post(process.env.REACT_APP_BECOME_PARTNER_FORM_API_URL, formData);

      // Reset form data
      setFormData({
        country: '',
        city: '',
        businessType: '',
        storeName: '',
        ownerName: '',
        phoneNumber: '',
        email: '',
        referralCode: '',
      });

      alert(response.data.message);
    } catch (error) {
      alert('There was an error submitting the form: ' + error.message);
    }
  };

  const logos = [...Array(20)].map((_, index) => (
    <img
      key={`logo-${index + 1}`}
      src={images[`Logo${index + 1}`]} // Assuming your images object is structured properly
      alt={`Logo ${index + 1}`}
      className="logo-img"
    />
  ));

  const rows = [];
  for (let i = 0; i < logos.length; i += 7) {
    rows.push(
      <div className="logo-row" key={`row-${i / 7}`}>
        {logos.slice(i, i + 7)}
      </div>
    );
  }

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-container">
        <div className="app__specialMenu-left">
          <div className="app__specialMenu-header">
            <h1>{t('specialMenu.header_title')}</h1>
            <h3>{t('specialMenu.header_subtitle')}</h3>
            <img src={images.orderFood} alt="Special Menu Header" />
            <div className="bottom-images">
              <img src={images.apple} alt="Apple" />
              <img src={images.google} alt="Google" />
            </div>
          </div>
        </div>
        <div className="app__specialMenu-right">
          <div className="form-container">
            <h2>{t('specialMenu.register_now')}</h2>
            <p>{t('specialMenu.register_now')}</p>
            <form onSubmit={handleSubmit}>
              <select defaultValue="" onChange={handleCountryChange}>
                <option value="" disabled>{t('specialMenu.choose_country')}</option>
                <option value="austria">{t('specialMenu.choose_country')} Austria</option>
                <option value="germany">{t('specialMenu.choose_country')} Germany</option>
                <option value="emirates">{t('specialMenu.choose_country')} Emirates</option>
              </select>
              <select defaultValue="" disabled={cities.length === 0} name="city" onChange={handleInputChange}>
                <option value="" disabled>{t('specialMenu.choose_city')}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city.toLowerCase()}>{city}</option>
                ))}
              </select>
              <select defaultValue="" name="businessType" onChange={handleInputChange}>
                <option value="" disabled>{t('specialMenu.choose_business_type')}</option>
                <option value="restaurant">{t('specialMenu.choose_business_type')} Restaurant</option>
                <option value="cafe">{t('specialMenu.choose_business_type')} Cafe</option>
                <option value="bakery">{t('specialMenu.choose_business_type')} Bakery</option>
                <option value="supermarket">{t('specialMenu.choose_business_type')} Supermarket</option>
                <option value="beauty">{t('specialMenu.choose_business_type')} Beauty</option>
                <option value="pharmacies">{t('specialMenu.choose_business_type')} Pharmacies</option>
                <option value="flowers">{t('specialMenu.choose_business_type')} Flowers</option>
                <option value="other">{t('specialMenu.choose_business_type')} Other stores</option>
              </select>
              <input type="text" placeholder={t('specialMenu.store_name_placeholder')} name="storeName" value={formData.storeName} onChange={handleInputChange} />
              <input type="text" placeholder={t('specialMenu.owner_name_placeholder')} name="ownerName" value={formData.ownerName} onChange={handleInputChange} />
              <input type="tel" placeholder={t('specialMenu.phone_placeholder')} name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
              <input type="email" placeholder={t('specialMenu.email_placeholder')} name="email" value={formData.email} onChange={handleInputChange} />
              <input type="text" placeholder={t('specialMenu.referral_code_placeholder')} name="referralCode" value={formData.referralCode} onChange={handleInputChange} />
              <button type="submit">{t('specialMenu.receive_registration_panel')}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialMenu;
