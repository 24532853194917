import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ComingSoon from './ComingSoon'; // Import the ComingSoon component
import './index.css';

// Get the root element
const container = document.getElementById('root');

// Function to determine which component to render based on the URL
const renderApp = () => {
  const path = window.location.pathname;
  if (path === '/coming-soon') {
    return <ComingSoon />;
  } else {
    return <App />;
  }
};

// Create a root
const root = createRoot(container);

// Initial render
root.render(
  <React.StrictMode>
    {renderApp()} {/* Render either the App or ComingSoon component based on the URL */}
  </React.StrictMode>
);
